import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { Link } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MainHeader from "./MainHeader";
import { useLocation } from "react-router-dom";
import dashboardIcon from "../assets/dashboardIcon.svg";
import {
  Dashboard,
  DeleteData,
  MerchantItem,
  Messages,
  SidebarUserItems,
} from "../utils/SideBarItem";
import { ApplicationItems } from "../utils/SideBarItem";
import { Notification } from "../utils/SideBarItem";
import { Commission } from "../utils/SideBarItem";
import { Reports } from "../utils/SideBarItem";
import { TransactionReport } from "../utils/SideBarItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloseIcon from "@mui/icons-material/Close";
import SideBarComponent from "./SideBarComponent/SideBarComponent";
import SideBarWithOutDropdown from "./SideBarComponent/SideBarWithOutDropdown";
import { useAppSelector } from "../redux/hooks";
import logo from ".././assets/crmLogo.png";

const drawerWidth = 260;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  paddingBottom: "3rem",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 32px)`,
  [theme.breakpoints.only("xs")]: {
    width: `calc(${theme.spacing(0)} + 0px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "left",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 1.5),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 4,
  [theme.breakpoints.only("xs")]: {
    zIndex: theme.zIndex.drawer + 0, // Set to 0 width on mobile devices
  },
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  [theme.breakpoints.only("xs")]: {
    width: 0,
  },
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideBar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const [open, setOpen] = React.useState(!isMobile);
  const { verifiedUser } = useAppSelector((state) => state.verifiedUser);
  const restrictedForAgent = verifiedUser?.data?.role !== "agent";

  const location = useLocation();
  const [collapseState, setCollapseState] = React.useState({
    isCollapse: false,
    isCollapseApplication: false,
    isCollapseCommission: false,
    isCollapseReport: false,
    isCollapseNtf: false,
    isCollapseTransactionReport: false,
    isCollapseDeleteData: false,
  });

  const handleCollapse = (key: any) => {
    setCollapseState((prevState: any) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isMobile && open) {
        const drawer = document.querySelector(".MuiDrawer-root");
        if (drawer && !drawer.contains(event.target as Node)) {
          handleDrawerClose();
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobile, open]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        // elevation={0}
        position="fixed"
        open={open}
        sx={{
          borderBottom: "1px solid #DCDCDC",
          backgroundColor: "#FFFFFF",
          boxShadow: "0 0px 6px #0000001a",
        }}
      >
        <Toolbar
          sx={{
            backgroundColor: "#fff",
            boxShadow: "none",
            padding: "14px 14px !important",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="end"
            sx={{
              marginRight: 5,
              color: "#898989",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <MainHeader showhide={open && isMobile ? false : true} />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton
            sx={{
              height: "55px",
              justifyContent: "center",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "transparent !important",
              },
            }}
          >
            {theme.direction === "rtl" ? (
              <MenuIcon onClick={handleDrawerClose} />
            ) : (
              <>
                <List
                  sx={{
                    display: "flex",
                    alignItems: "left",
                    gap: 1,
                    // justifyContent: "center",
                    // alignItem: "center",
                    // marginBottom: "1rem",
                    height: "70px",
                  }}
                >
                  <Box onClick={handleDrawerClose}>
                    {!isMobile ? (
                      <MenuIcon style={{ marginTop: "20px" }} />
                    ) : (
                      <CloseIcon style={{ marginTop: "20px" }} />
                    )}
                  </Box>
                  <Link
                    // to="/dashboard"
                    to={
                      restrictedForAgent
                        ? "/dashboard"
                        : "/application/viewApplications"
                    }
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      textDecoration: "none",
                    }}
                  >
                    {/* <img src={dashboardIcon} alt="icon" />
                    <Typography
                      sx={{
                        color: "#2E2C34",
                        fontWeight: "700 !important",
                        fontSize: "1rem !important",
                        fontFamily: "Inter",
                      }}
                    >
                      Card Transact
                    </Typography> */}
                    <img
                      src={logo}
                      alt="logo"
                      style={{ width: 190, height: 70 }}
                    />
                  </Link>
                </List>
              </>
            )}
          </IconButton>
        </DrawerHeader>
        {/* Dashboard */}
        {restrictedForAgent && (
          <SideBarWithOutDropdown
            items={Dashboard}
            sx={{ marginTop: "0.7rem" }}
            location={location}
            open={open}
          />
        )}
        {/* User */}
        {restrictedForAgent && (
          <SideBarComponent
            items={SidebarUserItems}
            isCollapsed={collapseState.isCollapse}
            handleCollapse={() => handleCollapse("isCollapse")}
            location={location}
            open={open}
          />
        )}
        {/* Application */}
        <SideBarComponent
          items={ApplicationItems}
          isCollapsed={collapseState.isCollapseApplication}
          handleCollapse={() => handleCollapse("isCollapseApplication")}
          location={location}
          open={open}
        />
        {/* Merchant */}
        {restrictedForAgent && (
          <SideBarWithOutDropdown
            items={MerchantItem}
            location={location}
            open={open}
          />
        )}
        {/* Commission */}
        <SideBarComponent
          items={Commission}
          isCollapsed={collapseState.isCollapseCommission}
          handleCollapse={() => handleCollapse("isCollapseCommission")}
          location={location}
          open={open}
        />
        {/* Transaction Reports */}
        {restrictedForAgent && (
          <SideBarWithOutDropdown
            items={TransactionReport}
            location={location}
            open={open}
          />
        )}
        {/* Reports */}
        {restrictedForAgent && (
          <SideBarComponent
            items={Reports}
            isCollapsed={collapseState.isCollapseReport}
            handleCollapse={() => handleCollapse("isCollapseReport")}
            location={location}
            open={open}
          />
        )}
        {/* Notification */}
        <SideBarComponent
          items={Notification}
          isCollapsed={collapseState.isCollapseNtf}
          handleCollapse={() => handleCollapse("isCollapseNtf")}
          location={location}
          open={open}
        />
        {/* Messages */}
        <SideBarWithOutDropdown
          items={Messages}
          location={location}
          open={open}
          notificationShow={true}
        />
        {/* delete data */}
        {restrictedForAgent && (
          <SideBarComponent
            items={DeleteData}
            isCollapsed={collapseState.isCollapseDeleteData}
            handleCollapse={() => handleCollapse("isCollapseDeleteData")}
            location={location}
            open={open}
          />
        )}
      </Drawer>
    </Box>
  );
}
