import { Navigate } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";

const PrivateRoute = ({ children }) => {
  const { verifiedUser } = useAppSelector((state) => state?.verifiedUser);

  const isAgent = verifiedUser?.data.role === "agent";

  return isAgent ? <Navigate to="/application/viewApplications" /> : children;
};

export default PrivateRoute;
