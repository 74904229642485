import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import Apis from "../../../utils/apis";
import { API_AXIOS } from "../../../http/interceptor";

interface UserToken {
  token: string;

}

interface OtpParams {
  email: string;
  otp: string;
  type: string;
  
}



export const userVerify = createAsyncThunk<UserToken, OtpParams, { rejectValue: AxiosError }>(
  "user/verifyOtp",
  async ({ email, otp, type }, { rejectWithValue }) => {
    try {
      const { data } = await API_AXIOS.post(
        Apis.veryfyOtp,
        { email, otp },
        {
            params: {
              type: type
            },
          }
      );
      localStorage.setItem("token", data?.data?.token);
      localStorage.setItem("info", JSON.stringify(data));
      console.log("userVerfy", data)
      toast.success(data?.message);
      return data;

    } catch (error: any) {
      toast.error(error.response?.data?.message)
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data?.message);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);
export default userVerify;







