//import { Middleware } from 'redux'
import { configureStore } from "@reduxjs/toolkit";
import verifyOtpReducer from "./reducers/verifyOtp";
//import employeeReducer from "./reducers/employee";
// import { employeeApi } from "./services/employee";
// import { getAgentApi } from "./services/agent";
// import { transactionReportApi } from "./services/transactionReport";

export const store = configureStore({
  reducer: {
    verifiedUser: verifyOtpReducer,
    //employee: employeeReducer,
    //[employeeApi.reducerPath]: employeeApi.reducer,
    //[getAgentApi.reducerPath]: getAgentApi.reducer,
    //[transactionReportApi.reducerPath]: transactionReportApi.reducer,
  },
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(
  //     employeeApi.middleware,
  //     getAgentApi.middleware,
  //     transactionReportApi.middleware
  //   ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
