import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import SideBar from "../components/SideBar";
import { useAppSelector } from "../redux/hooks";
import { useNavigate } from "react-router-dom";

export const MainLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { verifiedUser } = useAppSelector((state) => state.verifiedUser);

  useEffect(() => {
    if (!verifiedUser) {
      sessionStorage.setItem("requestUrl", pathname);
      navigate("/auth/login");
      return;
      // } else if (pathname === "/" && verifiedUser?.role !== "agent") {
      //   navigate("/dashboard");
      // }
    }
    if (pathname === "/") {
      if (verifiedUser?.data?.role === "agent") {
        navigate("/application/viewApplications");
      } else if (verifiedUser?.data?.role !== "agent") {
        navigate("/dashboard");
      }
    }
  }, [verifiedUser, navigate, pathname]);

  if (!verifiedUser) {
    navigate("/auth/login");
    return null;
  }

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <SideBar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: "75%",
          p: "3rem 1rem",
          display: "flex",
          "@media(max-width: 1200px)": {
            width: "66%",
            "@media(max-width: 900px)": {
              width: "58%",
              "@media(max-width: 600px)": {
                p: "2.5rem  0.8rem",
              },
            },
          },
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
