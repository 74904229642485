export let baseURL = process.env.REACT_APP_BASE_URL;
// export let baseURL = `https://card-transact-api.onrender.com/api`;
//export let baseURL = `http://10.5.50.5:8000/api`;
// export let baseURL = `http://localhost:8000/api`;

const Apis = {
  baseURL,
  login: `${baseURL}/auth/login`,
  veryfyOtp: `${baseURL}/auth/verifyOTP`,
  AddEmployee: `${baseURL}/emp`,
  getEmployee: `${baseURL}/emp`,
  empUpdateById: `${baseURL}/emp/editRequestEmpById`,
  agent: baseURL + "/agent",
  agentDetailsById: baseURL + "/getAgentById",
  addAgentReqApproveRejectById: `${baseURL}/addAgentReqApproveRejectById`,
  editRequestAgentById: baseURL + "/editRequestAgentById",
  editAgentReqApproveRejectById: baseURL + "/editAgentReqApproveRejectById",
  agentMoveToRecycleById: `${baseURL}/agentMoveToRecycleById`,
  deleteAgentById: `${baseURL}/deleteAgentById`,
  deleteAgentRequestById: `${baseURL}/deleteAgentRequestById`,
  getEmpDetailsById: `${baseURL}/emp/getEmpById`,
  deleteEmpReq: `${baseURL}/emp/deleteEmpRequestById`,
  deleteEmpById: `${baseURL}/emp/deleteEmpById`,
  editEmpReqApprovedRejectById: baseURL + "/emp/editEmpReqApproveRejectById",
  addEmpReqApproveRejectById: baseURL + "/emp/addEmpReqApproveRejectById",
  empMoveToRecycleById: `${baseURL}/emp/empMoveToRecycleById`,
  empAddRemarks: `${baseURL}/emp/addSpecialRemarks`,

  tier: `${baseURL}/tier`,
  acquirer: `${baseURL}/acquirer`,
  addApplication: `${baseURL}/application`,

  applicationRestoreById: `${baseURL}/applicationRestoreById`,
  applicationMoveToTrash: `${baseURL}/applicationMoveToRecycleById`,
  deleteApplicationById: `${baseURL}/deleteApplicationById`,

  getAppDetailById: `${baseURL}/getApplicationById`,
  allApplications: `${baseURL}/application`,
  reviewAppById: `${baseURL}/reviewAppById`,
  getModelsByAcquirer: `${baseURL}/comStruct/getModelsByAcquirer`,
  getOptionsByModel: `${baseURL}/comStruct/getOptionsByModel`,
  forwardedAppById: `${baseURL}/forwardedAppById`,
  ProfileImgUpload: `${baseURL}/agent/profilePicture`,
  EmpProfileImgUpload: `${baseURL}/emp/profilePicture`,

  OtpForgetPassword: `${baseURL}/auth/forgetPassword`,
  verifyOtpForget: `${baseURL}/auth/verifyOTPForget`,
  ForgetResetPassword: `${baseURL}/auth/resetPassword`,
  changePassword: `${baseURL}/auth/changePassword`,

  empToggleActive: `${baseURL}/employeeToggleActive`,
  agentToggleActive: `${baseURL}/agentToggleActive`,

  agentRestoreById: `${baseURL}/agentRestoreById`,
  empRestoreById: `${baseURL}/emp/empRestoreById`,

  // Trnasaction Report
  addTransactionReport: `${baseURL}/addTransactionReport`,
  getTransactionReports: `${baseURL}/transaction-reports`,
  transactionReportMoveToTrash: `${baseURL}/transactionReportMoveToTrash`,
  transactionReportRestore: `${baseURL}/transactionReportRestore`,
  transactionReportDelete: `${baseURL}/transactionReportDelete`,
  getTrDetailsByID: `${baseURL}/getTransactionById`,

  /* Commission */
  addCommissionStruct: `${baseURL}/comStruct`,
  getCommissionStruct: `${baseURL}/comStruct`,
  // getAllCommissions: `${baseURL}/getAllCommissionsList`,
  getAllCommissions: `${baseURL}/getCommission`,
  getCommissionDetailsByID: `${baseURL}/getCommissionDetails`,
  getCommStructure: `${baseURL}/getcommissionStructure`,
  getCommStruDetailsByID: `${baseURL}/comStruct/getCommissionStructureDetails`,
  getCardRateStructureDetails: `${baseURL}/getCardRateStructureDetails`,

  /* Card Rates */
  addCardRate: `${baseURL}/rates`,
  getCardRate: `${baseURL}/rates`,
  getRateByAcquirer: `${baseURL}/rates/getRatesByAcquirer`,
  cardRateDelete: `${baseURL}/rates/deleteRatesById`,

  /* Notification */
  addNotification: `${baseURL}/notification`,
  getNotification: `${baseURL}/notification`,
  getNotfDetailsByID: `${baseURL}/getNotificationDetails`,

  /* Merchant Details */
  getMerchant: `${baseURL}/getMerchant`,
  getMerchantDetailsByID: `${baseURL}/getMerchantById`,

  merchantRestoreById: `${baseURL}/merchantRestoreById`,
  merchantMoveToTrash: `${baseURL}/merchantMoveToRecycleById`,
  deletePrMerchantById: `${baseURL}/deleteMerchantById`,

  /* dashboard */
  getTopPerformers: `${baseURL}/getTopPerformers`,
  getApplicationDash: `${baseURL}/getDashboardData`,
  getCardDashboardData: `${baseURL}/getCardDashboardData`,
  /* for agent */
  getAgentDashboardCard: `${baseURL}/getAgentCardDashboardData`,

  /* Report */
  addSale: `${baseURL}/addSales`,
  getSaleDashbordData: `${baseURL}/getSales`,
  getCommReceived: `${baseURL}/getCommissionReceivedData`,
  addCommission: `${baseURL}/addCommissionReceivedData`,
  addCommissionPaid: `${baseURL}/addCommissionPaidData`,
  addClawbackReport: `${baseURL}/addClawbackReport`,
  getCommissionPaidData: `${baseURL}/getCommissionPaidData`,
  getClawbackData: `${baseURL}/getClawbackData`,
  addResidualReceived: `${baseURL}/addResidualReceivedData`,
  addResidualPaidData: `${baseURL}/addResidualPaidData`,
  getResidualReceived: `${baseURL}/getResidualReceivedData`,
  getResidualPaidData: `${baseURL}/getResidualPaidData`,
  getRevenueDashboard: `${baseURL}/getAllRevenueDashboard`,
  getSales: `${baseURL}/getSalesData`,
  getTopSalesPerson: `${baseURL}/top-salespersons`,

  CommissionGraph: `${baseURL}/reportDashboardCommission`,
  getComissionPaidGraph: `${baseURL}/reportDashboardPaidCommission`,
  ResidualGraph: `${baseURL}/reportDashboardResidual`,
  getResidualPaidGraph: `${baseURL}/reportDashboardResidualPaid`,
  clawBackGraph: `${baseURL}/reportDashboardClawback`,

  /* Commission received */
};

export default Apis;
