import React from "react";
import { lazy } from "react";
import { Box } from "@mui/material";
import {
  RouterProvider,
  createBrowserRouter,
  // useRouteError,
} from "react-router-dom";
import Loadable from "./components/Loadable";
import { MainLayout } from "./layouts/MainLayout";
import PrivateRoute from "./components/PrivateRoute";

/* auth */
const Login = Loadable(lazy(() => import("./pages/Auth/Login")));
const GoogleAuthentication = Loadable(
  lazy(() => import("./pages/Auth/GoogleAuthentication"))
);
const ForgetPassword = Loadable(
  lazy(() => import("./pages/Auth/ForgetPassword"))
);
const OtpForgotPassword = Loadable(
  lazy(() => import("./pages/Auth/OtpForgotPassword"))
);
const ResetPassword = Loadable(
  lazy(() => import("./pages/Auth/ResetPassword"))
);
const UserProfile = Loadable(lazy(() => import("./pages/Auth/UserProfile")));

/* main */
const Dashboard = Loadable(lazy(() => import("./pages/Dashboard/Dashboard")));
const ViewAgents = Loadable(lazy(() => import("./pages/Users/ViewAgents")));
const AgentDetails = Loadable(lazy(() => import("./pages/Users/AgentDetails")));
const ViewEmployees = Loadable(
  lazy(() => import("./pages/Users/ViewEmployees"))
);
const EmployeesDetails = Loadable(
  lazy(() => import("./pages/Users/EmployeesDetails"))
);
const ApplicationDetails = Loadable(
  lazy(() => import("./pages/Applications/ApplicationDetails"))
);
const AddEmployees = Loadable(lazy(() => import("./pages/Users/AddEmployees")));
const AddAgents = Loadable(lazy(() => import("./pages/Users/AddAgents")));

/* applications */
const Addapplication = Loadable(
  lazy(() => import("./pages/Applications/Addapplication"))
);
const ViewApplication = Loadable(
  lazy(() => import("./pages/Applications/ViewApplication"))
);

const ReEvaluation = Loadable(
  lazy(() => import("./pages/Applications/ReEvaluation"))
);

/* Transaction Report */
const TransactionReport = Loadable(
  lazy(() => import("./pages/TransactionReport/TransactionReport"))
);
const ViewReport = Loadable(
  lazy(() => import("./pages/TransactionReport/ViewReport"))
);
const AddTransactionStructure = Loadable(
  lazy(() => import("./pages/TransactionReport/AddTransactionStructure"))
);

/* reports */
const Overview = Loadable(lazy(() => import("./pages/Reports/Overview")));
const AddSale = Loadable(lazy(() => import("./pages/Reports/AddSale")));
const AddCommission = Loadable(
  lazy(() => import("./pages/Reports/AddCommission"))
);
const AddCommissionPaid = Loadable(
  lazy(() => import("./pages/Reports/AddCommissionPaid"))
);
const AddClawback = Loadable(lazy(() => import("./pages/Reports/AddClawback")));
const AddResidual = Loadable(lazy(() => import("./pages/Reports/AddResidual")));
const CommissionPaid = Loadable(
  lazy(() => import("./pages/Reports/CommissionPaid"))
);
const Sales = Loadable(lazy(() => import("./pages/Reports/Sales")));
const CommissionReceived = Loadable(
  lazy(() => import("./pages/Reports/CommissionReceived"))
);

const ResidualReceived = Loadable(
  lazy(() => import("./pages/Reports/ResidualReceived"))
);
const ResidualPaid = Loadable(
  lazy(() => import("./pages/Reports/ResidualPaid"))
);
const Clawbacks = Loadable(lazy(() => import("./pages/Reports/Clawbacks")));

/* merchants */
const Merchant = Loadable(lazy(() => import("./pages/Merchant/Merchant")));
const MerchantDetail = Loadable(
  lazy(() => import("./pages/Merchant/MerchantDetails"))
);

/* commissions */
const AllCommission = Loadable(
  lazy(() => import("./pages/Commission/AllCommission"))
);
const CommissionStructure = Loadable(
  lazy(() => import("./pages/Commission/CommissionStructure"))
);
const ViewStructure = Loadable(
  lazy(() => import("./pages/Commission/ViewStructure"))
);
const CommissionDetails = Loadable(
  lazy(() => import("./pages/Commission/CommissionDetails"))
);
const AddCommissionStructure = Loadable(
  lazy(() => import("./pages/Commission/AddCommissionStructure"))
);
const AddResidualPaid = Loadable(
  lazy(() => import("./pages/Reports/AddResidualPaid"))
);
const AddCardRateStructure = Loadable(
  lazy(() => import("./pages/Commission/AddCardRateStructure"))
);
const ViewCommissionStructure = Loadable(
  lazy(() => import("./pages/Commission/ViewCommissionStructure"))
);
const AllCardRates = Loadable(
  lazy(() => import("./pages/Commission/AllCardRates"))
);
const ViewCardRates = Loadable(
  lazy(() => import("./pages/Commission/ViewCardRates"))
);
const CommStructDetails = Loadable(
  lazy(() => import("./pages/Commission/ViewCommissionStructure"))
);

/* notifications */
const AllNotification = Loadable(
  lazy(() => import("./pages/Notifications/AllNotification"))
);
const CreateNotification = Loadable(
  lazy(() => import("./pages/Notifications/CreateNotification"))
);
const ReceivedNotification = Loadable(
  lazy(() => import("./pages/Notifications/ReceivedNotification"))
);
const NotificationDetails = Loadable(
  lazy(() => import("./pages/Notifications/NotificationDetails"))
);

/* Message */
const Message = Loadable(lazy(() => import("./pages/Messages/Message")));

function App() {
  const router = createBrowserRouter([
    {
      path: "/auth/login",
      element: <Login />,
    },
    {
      path: "/auth/googleAuthentication",
      element: <GoogleAuthentication />,
    },
    {
      path: "/auth/ForgotPassword",
      element: <ForgetPassword />,
    },
    {
      path: "/auth/OtpForgotPassword",
      element: <OtpForgotPassword />,
    },
    {
      path: "/auth/resetPassword",
      element: <ResetPassword />,
    },
    {
      path: "/",
      element: <MainLayout />,
      errorElement: <ErrorBoundary />,
      children: [
        {
          path: "/dashboard",
          element: (
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          ),
        },
        /* User Profile */
        {
          path: "/user/userProfile",
          element: <UserProfile />,
        },
        /*User SideBar List***************************/
        {
          path: "/user/addAgent",
          element: <AddAgents />,
        },
        {
          path: "/user/viewAgents",
          element: <ViewAgents />,
        },
        {
          path: "/user/viewAgents/viewDetails/:id",
          element: <AgentDetails />,
        },
        /*Employees SideBar List************************/
        {
          path: "/user/addEmployee",
          element: <AddEmployees />,
        },
        {
          path: "/user/viewEmployee",
          element: <ViewEmployees />,
        },
        {
          path: "/user/viewEmployee/viewDetails/:id",
          element: <EmployeesDetails />,
        },
        /*Application SideBar List************************/
        {
          path: "/application/addApplication",
          element: <Addapplication />,
        },
        {
          path: "/application/viewApplications",
          element: <ViewApplication />,
        },

        {
          path: "/application/viewApplications/applicationDetails/:id",
          element: <ApplicationDetails />,
        },
        {
          path: "/application/viewApplications/sendForRe_evaluation",
          element: <ReEvaluation />,
        },
        /*Merchant SideBar List************************/
        {
          path: "/merchants",
          element: <Merchant />,
        },
        {
          path: "/merchants/merchantDetail/:id",
          element: <MerchantDetail />,
        },
        /*Commission SideBar List************************/
        {
          path: "/commission/commissionStructure/addCommissionStructure",
          element: <AddCommissionStructure />,
        },
        {
          path: "/commission/commissionStructure/addCommissionStructure/viewCommission",
          element: <ViewCommissionStructure />,
        },
        {
          path: "/commission/allCommission",
          element: <AllCommission />,
        },
        {
          path: "/commission/allCardRates",
          element: <AllCardRates />,
        },
        {
          path: "/commission/allCardRates/AddCardRateStructure",
          element: <AddCardRateStructure />,
        },
        {
          path: "/commission/commissionStructure",
          element: <CommissionStructure />,
        },
        {
          path: "/commission/allCommission/commissionDetails/:id",
          element: <CommissionDetails />,
        },
        {
          path: "/commission/commissionStructure/viewCommissionStructure/:id",
          element: <CommStructDetails />,
        },
        {
          path: "/commission/viewStructure",
          element: <ViewStructure />,
        },
        {
          path: "/commission/allCardRates/viewCardRates/:id",
          element: <ViewCardRates />,
        },
        /* Transaction Report ********************/
        {
          path: "/transactionReport",
          element: <TransactionReport />,
        },
        {
          path: "/transactionReport/addTransactionReport",
          element: <AddTransactionStructure />,
        },
        {
          path: "/transactionReport/viewReport",
          element: <ViewReport />,
        },
        /*Reports SideBar List************************/
        {
          path: "/report/overView",
          element: <Overview />,
        },
        {
          path: "/report/overview/salesDetails",
          element: <Sales />,
        },
        {
          path: "/report/overview/addSale",
          element: <AddSale />,
        },
        {
          path: "/report/overview/Addcommission",
          element: <AddCommission />,
        },

        {
          path: "/report/overview/commissionReceivedDetails",
          element: <CommissionReceived />,
        },
        {
          path: "/report/overview/commissionPaidDetails",
          element: <CommissionPaid />,
        },
        {
          path: "/report/overview/residualReceivedDetails",
          element: <ResidualReceived />,
        },
        {
          path: "/report/overview/addResidual",
          element: <AddResidual />,
        },
        {
          path: "/report/overview/addCommissionPaid",
          element: <AddCommissionPaid />,
        },
        {
          path: "/report/overview/addResidualPaid",
          element: <AddResidualPaid />,
        },
        {
          path: "/report/overview/residualPaidDetails",
          element: <ResidualPaid />,
        },
        {
          path: "/report/overview/addClawback",
          element: <AddClawback />,
        },
        {
          path: "/report/overview/clawbacks",
          element: <Clawbacks />,
        },

        /*Notification SideBar List************************/
        {
          path: "/notifications/notificationList",
          element: <AllNotification />,
        },
        {
          path: "/notifications/notificationList/notificationDetails/:id",
          element: <NotificationDetails />,
        },
        {
          path: "/notifications/createNotification",
          element: <CreateNotification />,
        },
        {
          path: "/notifications/receivedNotification",
          element: <ReceivedNotification />,
        },
        {
          path: "/notifications/receivedNotification/notificationDetails/:id",
          element: <NotificationDetails />,
        },
        /*Notification SideBar List************************/
        {
          path: "/messages",
          element: <Message />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

function ErrorBoundary() {
  // let error = useRouteError();
  // console.error(error);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
        backgroundColor: "#f8f8f8",
      }}
    >
      <h1>An error occurred</h1>
      <p>Sorry, something went wrong.</p>
    </Box>
  );
}
export default App;
