import React, { useCallback, useEffect, useState } from "react";
// import TextField from "@mui/material/TextField";
// import IconButton from "@mui/material/IconButton";
// import SearchIcon from "@mui/icons-material/Search";
import { Avatar, Badge, Box, Typography } from "@mui/material";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
// import InputAdornment from "@mui/material/InputAdornment";
import profileIcon from "../assets/profileIcon.svg";
import Menu from "@mui/material/Menu";
//import MenuItem from "@mui/material/MenuItem";
//import { Colors } from "../utils/Colors";
import { logout } from "../redux/store/reducers/verifyOtp";
import logoutIcon from "../assets/logout.svg";
import profileIcon1 from "../assets/profileIcon1.svg";
import Notification from "./Notification";
//import CustomButton from "./CustomButton";
import { Link, useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useNavigate } from "react-router-dom";

interface mainHeaderProps {
  style?: React.CSSProperties;
  showhide?: boolean;
}

const MainHeader: React.FC<mainHeaderProps> = ({ style, showhide = true }) => {
  const { verifiedUser } = useAppSelector((state) => state.verifiedUser);
  //const [searchValue, setSearchValue] = useState<string>("");

  const [openProfile, setOpenProfile] = React.useState<null | HTMLElement>(
    null
  );
  const dispatch = useAppDispatch();
  const [openNotification, setOpenNotification] = React.useState<null>(null);
  const [badgeContent, setBadgeContent] = useState<string | number>(" ");

  const openprofile = Boolean(openProfile);
  const openNot = Boolean(openNotification);
  const navigate = useNavigate();
  const location = useLocation();

  // const handleOpenNotification = (event: any) => {
  //   setOpenNotification(event.currentTarget);
  // };

  // const handleOpenNotification = () => {
  //   navigate("/notifications/receivedNotification?screen=receivedList"); // Redirect to the notifications page
  // };

  const handleOpenNotification = () => {
    const currentPath = location.pathname + location.search;
    const notificationPath =
      "/notifications/receivedNotification?screen=receivedList";

    if (currentPath !== notificationPath) {
      setBadgeContent(0);
      navigate(notificationPath);
    } else {
      setBadgeContent(1);
      navigate(-1);
    }
  };

  // useEffect(() => {
  //   const notificationPath =
  //     "/notifications/receivedNotification?screen=receivedList";
  //   if (location.pathname + location.search === notificationPath) {
  //     setBadgeContent(0); // Ensure badge is hidden on the notifications page
  //   }
  // }, [location]);
  // const handleCloseNotification = () => {
  //   setOpenNotification(null);
  // };

  const handleClick = (event: any) => {
    setOpenProfile(event.currentTarget);
  };
  const handleClose = () => {
    setOpenProfile(null);
  };

  // const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSearchValue(event.target.value);
  // };

  // const handleSearchSubmit = () => {
  //   console.log("Search value:", searchValue);
  // };

  const handleLinkClick = () => {
    handleClose();
    navigate("/user/userProfile");
  };

  const handleLogout = useCallback(() => {
    localStorage.clear();
    dispatch(logout());
    navigate("/auth/login");
  }, [dispatch, navigate]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "right",
        height: "2.5rem",
        alignItems: "center",
        marginRight: "1rem",
        flexGrow: 1,
        ...style,
      }}
    >
      {/* {showhide && (
        <TextField
          // onChange={handleSearchChange}
          //variant="filled"
          placeholder="Search..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton size="small">
                  <SearchIcon sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              </InputAdornment>
            ),
            style: {
              borderRadius: "2rem",
              backgroundColor: "#F9F9F9",
              // alignItems: "flex-start",
              borderBottom: "none !important",
            },
            inputProps: {
              style: {
                height: "1.5rem",
                padding: "4px",
              },
            },
          }}
          sx={{
            width: "50%",
            "@media(max-width: 600px)": {
              width: "70%",
            },
          }}
        />
      )} */}
      <Box
        sx={{
          display: "flex",
          gap: 3,
          justifyContent: "right",
          alignItems: "center",
        }}
      >
        {verifiedUser?.data?.role !== "agent" && (
          <Badge
            color="error"
            //variant="dot"
            variant="dot"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            //badgeContent={1}
            sx={{ cursor: "pointer", color: "#F9837C" }}
            onClick={handleOpenNotification}
            badgeContent={badgeContent}
          >
            <CircleNotificationsIcon
              sx={{ color: "#898989", width: "2rem", height: "2rem" }}
            />
          </Badge>
        )}
        <Avatar
          src={verifiedUser?.data?.profilePicture || profileIcon}
          alt=""
          style={{ cursor: "pointer" }}
          sx={{
            width: { xs: 30, md: 30 },
            height: { xs: 30, md: 30 },
            backgroundColor: "#898989",
            position: "relative",
          }}
          onClick={handleClick}
        />
      </Box>
      <Menu
        id="basic-menu"
        PaperProps={{
          style: {
            borderRadius: "1rem",
            border: "1px solid rgba(236, 236, 236, 1)",
            boxShadow: " -4px 4px 8px 0.5px rgba(0, 0, 0, 0.3)",
          },
        }}
        anchorEl={openNotification}
        disableScrollLock={true}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        open={openNot}
        // onClose={handleCloseNotification}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* <Notification /> */}
      </Menu>

      <Menu
        id="basic-menu"
        anchorEl={openProfile}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        open={openprofile}
        onClose={handleClose}
        disableScrollLock={true}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box sx={{ p: 1, display: "flex", flexDirection: "column", gap: 2 }}>
          {/* <Link
            to="/user/userProfile"
            onClick={handleLinkClick}
            style={{ display: "flex", gap: "7px", textDecoration: "none" }}
          >
            <img src={profileIcon1} alt="" />
            <Typography
              sx={{
                color: "#898989",
                fontWeight: "400",
                fontSize: "14px",
                fontFamily: "Inter",
              }}
            >
              View Profile
            </Typography>
          </Link> */}
          <Box
            sx={{
              display: "flex",
              gap: "7px",
              textDecoration: "none",
              cursor: "pointer",
            }}
            onClick={handleLinkClick}
          >
            <img src={profileIcon1} alt="" />
            <Typography
              sx={{
                color: "#898989",
                fontWeight: "400",
                fontSize: "14px",
                fontFamily: "Inter",
              }}
            >
              View Profile
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", gap: "7px", cursor: "pointer" }}
            onClick={handleLogout}
          >
            <img src={logoutIcon} alt="" />
            <Typography
              sx={{
                color: "#898989",
                fontWeight: "400",
                fontSize: "14px",
                fontFamily: "Inter",
              }}
            >
              Logout
            </Typography>
          </Box>
        </Box>
      </Menu>
    </Box>
  );
};

export default MainHeader;
